import React, { useEffect, useState } from "react";

import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import {ReactComponent as SvgRefresh}   from 'bootstrap-icons/icons/arrow-clockwise.svg'
import {ReactComponent as SvgEdit}   from 'bootstrap-icons/icons/three-dots.svg'

import OSevenGrid from './osevengrid'
import ModalInstanceDetails from './ModalInstanceDetails'
import ModalLaunchPipeline from './ModalLaunchPipeline'




function ListInstances(props) {

    const [instances, setInstances] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showLaunchModal, setShowLaunchModal] = useState(false);
    const [selected, setSelected] = useState(0);

    const columns = [
        { title: 'Name'     , src: 'project' },
        { title: 'Account'  , src: 'account_id' },
        { title: 'Status'   , src: 'pipeline_status' },
        { title: 'Infra'    , src: 'infra_version' },
        { title: 'Backend'  , src: 'backend_version' },
        { title: 'Frontend' , src: 'frontend_version' },
        { title: 'Update'   , src: 'updated_since' },
        // { title: 'Api Rq'   , src: 'api_request_count' },
        // { title: 'Lat(ms)'  , src: 'api_latency' },
        { title: 'State/24h' , src: 'system_status' },
        { title: '7d/30d'     , src: 'system_uptime' },
        { title: ''         , custom: 0 },
    ];
    const groups = [
        { title: 'Project'          , colcount: 2 },
        { title: 'Pipeline'         , colcount: 1 },
        { title: 'Actual Versions'  , colcount: 3 },
        { title: 'Last'             , colcount: 1 },
        { title: 'Uptime'         , colcount: 2 },
        { title: ''             , colcount: 1 },
    ];

    function OpenDetailModal(rowId) {
        return (
            <>
            <Button
            variant="primary" size="sm"
            onClick={() => {setShowDetailModal(true); setSelected(rowId); console.info(instances[rowId]);} }>
                <SvgEdit className="" width="16" height="16"/>
            </Button>
            <span className="m-1"></span>
            <Button
            variant="primary" size="sm" className=""
            onClick={() => {setSelected(rowId); console.info(instances[rowId]); ForceRefreshSelected()} }>
                <SvgRefresh className="" width="16" height="16"/>
            </Button>
            </>
        )
    }

    function OpenLaunchModal(new_updates) {
        setShowLaunchModal(true);
        setUpdates(new_updates);
        setShowDetailModal(false);
        console.info('Updating....');
        console.info(new_updates);
    }

    function LaunchUpdate() {
        props.apiService.updateInstance(updates, setErrorMsg, setSuccessMsg);
        setShowLaunchModal(false);
    }

    function ForceRefreshSelected() {
        props.apiService.forceInstanceRefresh(instances[selected], setErrorMsg, setSuccessMsg);
    }

    const customFunctions = [
        OpenDetailModal
    ];


    const refreshInstances = function () {

        const _refreshInstances = async () => {
            setErrorMsg('');
            const rx_data = await props.apiService.getAllInstances()

            if (rx_data.hasOwnProperty('error')) {
                setErrorMsg(rx_data.error);
                setInstances([]);
                return;
            }
            // console.info(rx_data);
            for (let index = 0; index < rx_data.length; index++) {
                //console.info(rx_data[index]);
                rx_data[index].api_request_count = rx_data[index].api_stats?.request_count;
                rx_data[index].api_latency = rx_data[index].api_stats?.latency_avr.toFixed(0);

            }


            setInstances(rx_data);
        };

        _refreshInstances();
    }

    useEffect(() => {
        // console.info(`useEffect.refreshRawData  for week ${week}`);
        refreshInstances();
    },[]);

    // props.apiService
    // refreshInstances();

    return (
        <>
        <div>
            <Alert show={errorMsg !== ''} onClose={() => setErrorMsg('')} variant="danger" dismissible>{errorMsg}</Alert>
        </div>
        <div>
            <Alert show={successMsg !== ''} onClose={() => setSuccessMsg('')} variant="success" dismissible>{successMsg}</Alert>
        </div>
        <OSevenGrid
            columns={columns}
            rows={instances}
            groups={groups}
            customFunctions={customFunctions}
        />
        <ModalInstanceDetails
            instance={instances[selected]}
            show={showDetailModal}
            handleClose={() => setShowDetailModal(false)}
            handleUpdate={OpenLaunchModal}
        />
        <ModalLaunchPipeline
            instance={instances[selected]}
            updates={updates}
            show={showLaunchModal}
            handleClose={() => setShowLaunchModal(false)}
            handleLaunch={LaunchUpdate}
        />

        </>

    );
}

export default ListInstances;