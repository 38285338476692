import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

const parameterRows = [
    { title: 'Infra Version',               src: 'infra/version',       type: 'text'},
    { title: 'Backend Version',             src: 'backend/version',     type: 'text'},
    { title: 'Frontend Version',            src: 'frontend/version',    type: 'text'},
    { title: 'Power BI - Tenant Id',        src: 'powerbi/ct-id',       type: 'text'},
    { title: 'Power BI - Report Id',        src: 'powerbi/report-id',   type: 'text'},
    { title: 'React Extras',                src: 'react/extra',         type: 'textarea'},
    { title: 'Backend Extras',              src: 'backend/extra',       type: 'textarea'},
    { title: 'Module - POM',                src: 'pom/version',         type: 'text'},
    { title: 'Module - ETL',                src: 'etl/version',         type: 'text'},
    { title: 'Module - Thickness Study',     src: 'modules/thickness-study/version', type: 'text'},
    { title: 'Module - Generic Report',     src: 'plugins/generic-reports/version', type: 'text'},
    { title: 'Module - RBI',                src: 'plugins/rbi/version', type: 'text'},
    { title: 'Module - GHG',                src: 'plugins/ghg/version', type: 'text'},
    { title: 'Module - IEE',                src: 'plugins/iee/version', type: 'text'},
    { title: 'Module - IEE Report',         src: 'plugins/iee-report/version', type: 'text'},
    { title: 'Module - Health Indicators',   src: 'modules/health-indicators/version', type: 'text'},
    { title: 'Module - PA-CAT',             src: 'plugins/pacat/version', type: 'text'},
    { title: 'Module - PA-CAT-TEST',        src: 'plugins/pacat-test/version', type: 'text'},
];

function UpdateParameters(instance, handleUpdate) {

    const project = instance.project;
    const instanceUpdate = {
        project : project,
        pipeline_name : instance.pipeline_name,
        account_id : instance.account_id,
        parameters : {}
    }
    console.info(`Updating Parameters for ${project}`);

    for (let i = 0; i < parameterRows.length; i++){
        let element = document.getElementById(`param-${i}`);
        if (element === null)
            continue;

        let newValue = element.value;
        let current = parameterRows[i].current;

        if ((current !== null) && (newValue !== current)) {
            console.info(`Updating ${parameterRows[i].title} current=${current} new=${newValue}`);
            instanceUpdate.parameters[parameterRows[i].src] = newValue
        }
    }
    console.info(instanceUpdate);
    handleUpdate(instanceUpdate)
}


function VersionRows(props) {

    const versions = props?.versions;
    const displayRows = [];

    if (versions === null) {
        return 'NA';
    }

    for (let key in versions) {
        displayRows.push({module : key, version : versions[key] });
    }


    const rows =  displayRows.map((column, index) => (
        <Row key={index} className="my-0">

            <Col xl="7">{column.module}</Col>
            <Col>{column.version}</Col>

        </Row>
    ))

    return (
        <>
        <Row><Col xl="7"><b>Module</b></Col><Col><b>Current</b></Col></Row>
        {rows}
        </>
    )
};


function ParameterRows(props) {

    const parameters = props?.parameters;
    const displayRows = []


    for (let i = 0; i < parameterRows.length; i++){
        parameterRows[i].current = null;
        if  (parameters.hasOwnProperty(parameterRows[i].src)) {
            parameterRows[i].current = parameters[parameterRows[i].src].value;
            parameterRows[i].index = i;
            displayRows.push(parameterRows[i])
        }
    }

    const rows =  displayRows.map((column, index) => (
        <Row key={index} className="my-1">
            <Col className='pt-1' xl="3">{column.title}</Col>

            {column.type === 'text' &&
                <>
                <Col className='pt-1'>{column.current}</Col>
                <Col><Form.Control id={`param-${column.index}`}type='text' defaultValue={column.current} /></Col>
                </>
            }
            {column.type === 'textarea' &&
                <>
                <Col><Form.Control  as="textarea" rows={5} size="sm" defaultValue={column.current} disabled readOnly /></Col>
                <Col><Form.Control id={`param-${column.index}`} as="textarea" rows={5} size="sm" defaultValue={column.current} /></Col>
                </>
            }

        </Row>
    ))

    return (
        <>
        <Row><Col></Col><Col><b>Current Target</b></Col><Col><b>New Target</b></Col></Row>
        {rows}
        </>
    )
};


function ModalInstanceDetails(props) {

    const instance = props.instance;
    const handleUpdate = props.handleUpdate;

    let tfvarContent = JSON.parse(instance?.parameters['infra/variables']?.value ?? "\"\"");

  return (
      <Modal size="xl" show={props.show} onHide={props.handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>{instance?.project} ({instance?.account_id})</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Row>
            <Col xl="3">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><b>Versions</b></Accordion.Header>
                        <Accordion.Body>
                        <VersionRows versions={instance?.versions}/>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><b>DB Parameters</b></Accordion.Header>
                        <Accordion.Body>
                            Engine: {instance?.db_stats.engine}
                            <br/>
                            Mode: {instance?.db_stats.engine_mode}
                            <br/>
                            Version: {instance?.db_stats.engine_version}
                            <br/>
                            Classes: {instance?.db_stats.instances_class}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><b>DB Statistics</b>(24 hours)</Accordion.Header>
                        <Accordion.Body>
                            CPU Average: {instance?.db_stats.cpu_avr.toFixed(2)}%
                            <br/>
                            Credit: {instance?.db_stats.credits_avr.toFixed(0)}
                            <br/>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><b>API Statistics</b>(24 hours)</Accordion.Header>
                        <Accordion.Body>
                            Latency Average: {instance?.api_stats.latency_avr.toFixed(0)} ms
                            <br/>
                            Request Count: {instance?.api_stats.request_count}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><b>Uptime</b></Accordion.Header>
                        <Accordion.Body>
                            24 hours: {instance?.status.uptime_api_24h.toFixed(4)}
                            <br/>
                            7 days: {instance?.status.uptime_api_07d.toFixed(4)}
                            <br/>
                            30 days: {instance?.status.uptime_api_30d.toFixed(4)}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </Col>
            <Col>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h5>System Parameters</h5></Accordion.Header>
                        <Accordion.Body>
                            <ParameterRows parameters={instance?.parameters}/>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><b>TFVARS</b></Accordion.Header>
                        <Accordion.Body>
                            <Form.Control  as="textarea" rows={20} size="sm" defaultValue={tfvarContent} disabled readOnly />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
            </Row>


        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => UpdateParameters(instance, handleUpdate)}>
            Launch Pipeline with New Parameters
          </Button>
        </Modal.Footer>
      </Modal>
  );
}


export default ModalInstanceDetails;
