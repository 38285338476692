import React, {useState } from "react";

import Header from './Header';
import Body from './Body';

import './App.css';


function App(props) {

  const [authState, setAuthState] = useState('loading');  // 'signedIn' | 'signedOut' | 'loading'

  async function updateUser() {

    let cognitoUser = await props.apiService.getUser()
    if (cognitoUser === null) {
      setAuthState('signedOut');
    }
    else {
      setAuthState('signedIn')
    }

  }

  updateUser()

  return (
      <div className="App">
        <Header authState={authState} apiService={props.apiService}/>
        {authState === 'signedIn' &&
          <Body apiService={props.apiService}></Body>
        }
      </div>
  );
}

export default App;
