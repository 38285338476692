import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {ReactComponent as SvgRocket}   from 'bootstrap-icons/icons/rocket-takeoff.svg'


function ParameterRows(props) {

    const updates = props.updates;
    const currents = props.currents;
    const parameters = updates.parameters
    const list = Object.keys(parameters)

    if (list.length === 0) {
        return ' None';
    }

    const rows =  list.map((parameter, index) => (
        <Row key={index} className="my-1">
            <Col className='pt-0'>{parameter}</Col>
            <Col className='pt-0'>{currents.parameters[parameter].value}</Col>
            <Col>{updates.parameters[parameter]}</Col>
        </Row>
    ))

    return (
        <>
        <Row><Col></Col><Col><b>Current</b></Col><Col><b>Future</b></Col></Row>
        {rows}
        </>
    )
};


function ModalLaunchPipeline(props) {

    const updates = props.updates;
    const instance = props.instance;

  return (
      <Modal size="md" show={props.show} onHide={props.handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Launching Pipeline <SvgRocket className="bi" width="32" height="32"/></Modal.Title>
        </Modal.Header>

        <Modal.Body>
            Project: <b>{updates?.project}</b>
            <br/>
            Parameters to be updated:
                <ParameterRows updates={updates} currents={instance}/>
        </Modal.Body>


        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={props.handleLaunch}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
  );
}


export default ModalLaunchPipeline;
