// https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/
// https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js/


export class ApiService  {

    constructor(auth, api) {
        this.auth           = auth;
        this.api            = api;
        this.cognitoUser    = null;
    }

    forceSignOut() {
        alert('Ciao, Bye !! ... TBD');
        this.auth.signOut();
    }


    async getUser() {
        try {
            this.cognitoUser = await this.auth.currentAuthenticatedUser();
            // console.info(this.cognitoUser);
            return this.cognitoUser
        } catch (e) {
            this.cognitoUser = null
            console.warn(e);
            return null;
        }
    }

    // https://docs.amplify.aws/lib/restapi/fetch/q/platform/react-native/
    async getAllInstances() {
        const authorizationHeader = await this.getAuthorizationHeader();
        return await this.api.get('skynet-api', "/instances", {
            headers: authorizationHeader,
        });
    }

    async testProjectInfo() {
        return await this.api.get('dev', "/api/project-info", {});
    }



    async updateInstance(updates, handleError, handleSuccess) {
        console.info('updateInstance: ', updates)
        try {
            const authorizationHeader = await this.getAuthorizationHeader();
            const execId = await this.api.post('skynet-api', "/update", {
                headers: authorizationHeader,
                body: updates
            });
            handleSuccess(`Successful launch for project ${updates.project}`);
        } catch (error) {

            let errMsg = `${error}`
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data'))
                errMsg = error.response.data;

            console.error(errMsg);

            handleError(`Houston, we have a problem: ${JSON.stringify(errMsg)}`)
        }
    }

    async forceInstanceRefresh(params, handleError, handleSuccess) {
        console.info('forceInstanceRefresh: ', params)
        try {
            const authorizationHeader = await this.getAuthorizationHeader();
            const execId = await this.api.post('skynet-api', "/refresh", {
                headers: authorizationHeader,
                body: params
            });
            handleSuccess(`Successful refresh for project ${params.project} on account ${params.account_id}`);
        } catch (error) {

            let errMsg = `${error}`
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data'))
                errMsg = error.response.data;

            console.error(errMsg);

            handleError(`There was an error: ${JSON.stringify(errMsg)}`)
        }
    }



    async getAuthorizationHeader() {
        const session = await this.auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        return { Authorization: idToken };
    }
}