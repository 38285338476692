
import ListInstances from './ListInstances';


function Body(props) {


    return (
        <main className="container">
            <ListInstances apiService={props.apiService}/>
        </main>
    );
}

export default Body;