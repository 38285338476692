import React, {useState } from "react";

import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';

import './osevengrid.css';

// Modal Ideas
// https://blog.bitsrc.io/build-a-simple-modal-component-with-react-16decdc111a6


function ErrorModal(props) {


    // Create List of Errores
    const errors = props.errors.map((error, index) => (
        <p className="text-danger" key={index}>{error}</p>
    ))

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Erreurs Feuille de Temps</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errors}</Modal.Body>
        </Modal>
    );
}


function OSevenGridCell(props) {

    let value = "";
    let subvalue = "";
    let badge = "";
    const className = [];
    const src = props.column.src;
    let errors = []
    let customFunctions = props.customFunctions
    let custom = ""
    let tooltip = ""

    const showErrors = function () {
        props.showErrors(errors)
    }

    if (props.data.hasOwnProperty(src)) {
        const data = props.data[src];

        // console.log(`${src} data=${data}`)

        if (data === null) {
            value = ''
        }
        else if (typeof data == "object") {
            if (data.hasOwnProperty('value')) value = data.value;
            if (data.hasOwnProperty('tooltip')) {
                tooltip = data.tooltip;
                className.push('hover-text');
            }
            if (data.hasOwnProperty('subvalue')) subvalue = data.subvalue;
            if (data.hasOwnProperty('badge')) badge = data.badge;
            if (data.hasOwnProperty('class')) className.push(data.class);
            if (data.hasOwnProperty('errors')) {
                errors = data.errors;
                if (typeof errors == 'object') {
                    if (data.errors.length > 0) className.push('text-danger fw-bold');
                }
            }
            if (data.hasOwnProperty('status')) {
                let cell_status = data.status;
                if (cell_status === 'success') className.push('text-success');
                if (cell_status === 'danger') className.push('text-danger fw-bold');
                if (cell_status === 'warning') className.push('text-warning fw-bold');
                if (cell_status === 'info') className.push('text-primary fw-bold');
            }

        }
        else {
            value = data
        }
    }

    if (props.column.hasOwnProperty('custom')){
        custom = customFunctions[props.column.custom](props.rowId)
    }

    if (errors.length > 0) {
        return (
            <td className={className.join(' ')} onClick={showErrors} >

                {value}
                {subvalue !== '' &&
                    <><br/><b>{subvalue}</b></>
                }
                {badge !== '' &&
                    <><br/><Badge pill bg="primary" >{badge}</Badge></>
                }
                {custom}
            </td>
        )
    }
    return (
        <td className={className.join(' ')} >
            {value}
            {subvalue !== '' &&
                <><br/>{subvalue}</>
            }
            {badge !== '' &&
                <><br/><Badge pill bg="primary">{badge}</Badge></>
            }
            {custom}
            {tooltip !== '' && <span class="tooltip-text" id="top">{tooltip}</span>}
        </td>
    )

};

function OSevenGridRow(props) {

    const tds =  props.columns.map((column, index) => (
        <OSevenGridCell key={index} rowId={props.rowId} column={column} data={props.data} customFunctions={props.customFunctions} showErrors={props.showErrors} />
    ))

    return (
        <tr>{tds}</tr>
    )
};


function OSevenGroups(props) {

    if (typeof props.groups === 'undefined') {
        return null;
    }

    const groups = props.groups.map((column, index) => (
        <th key={index} colSpan={column.colcount}>{column.title}</th>
    ))

    return (
        <tr className="o7group">
            {groups}
        </tr>
    )
};

function OSevenFooters(props) {

    if (typeof props.footers === 'undefined') {
        return null;
    }

    const footers = props.footers.map((data, index) => (
        <OSevenGridRow key={index} data={data} columns={props.columns} showErrors={props.showErrors}/>
    ))

    return (
        <tfoot className="table-group-divider align-top">
            {footers}
        </tfoot>
    )
};

// Grid to display in a React App
// Props
//      columns : Array of columns information
//              title: Text to set the header
//              src: Record name in the rows data
//      rows  : Array of the Data for each rows
//      footers: Array of the Data for footers

function OSevenGrid(props) {

    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState([]);
    const handleCloseError = () => setShowError(false);

    const showErrors = function (errors) {
        setErrors(errors)
        setShowError(true)
    }

    // Create the header
    const headers = props.columns.map((column, index) => (
        <th key={index}>{column.title}</th>
    ))

    // Generate All Rows
    const rows = props.rows.map((data, index) => (
        <OSevenGridRow key={index} rowId={index} data={data} columns={props.columns} customFunctions={props.customFunctions} showErrors={showErrors}/>
    ))



    return (
        <>
        <table className="table table-striped">
            <thead>
                <OSevenGroups groups={props.groups}></OSevenGroups>
                <tr>{headers}</tr>
            </thead>
            <tbody className="table-group-divider">
                {rows}
            </tbody>
            <OSevenFooters columns={props.columns} footers={props.footers} showErrors={showErrors} />
        </table>
        <ErrorModal show={showError} handleClose={handleCloseError} errors={errors} />
        </>

    )
};


export default OSevenGrid;

