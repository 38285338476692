const AWS_REGION              = process.env.REACT_APP_AWS_REGION;
const USER_POOL_ID            = process.env.REACT_APP_USER_POOL_ID;
const USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
const APP_URL                 = process.env.REACT_APP_APP_URL;
const OAUTH_DOMAIN            = process.env.REACT_APP_OAUTH_DOMAIN;
const REST_API_NAME           = 'skynet-api'
const REST_API_ENDPOINT       = process.env.REACT_APP_API_URL;


// eslint-disable-next-line
export default {
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: AWS_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,

      oauth: {

        domain: OAUTH_DOMAIN,
        scope: ['openid', 'profile'],
        redirectSignIn: APP_URL,
        redirectSignOut: APP_URL,
        responseType: 'code', // code or token
        // optional, for Cognito hosted ui specified options
        options: {
          // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
          AdvancedSecurityDataCollectionFlag: true
        }
      }

      /*// OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
      },

      // OPTIONAL - customized storage object
      storage: new MyStorage(),

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH'*/

    },

    API: {
      endpoints: [
        {
          name: REST_API_NAME,
          endpoint: REST_API_ENDPOINT
        }
      ]
    }
  }