
import React from "react";
import {Auth} from 'aws-amplify';

import Button from 'react-bootstrap/Button';

import {ReactComponent as SvgHome}      from 'bootstrap-icons/icons/house.svg'
//import {ReactComponent as SvgRefresh}   from 'bootstrap-icons/icons/arrow-clockwise.svg'
import {ReactComponent as SvgSignin}    from 'bootstrap-icons/icons/door-open.svg'
import {ReactComponent as SvgSignout}   from 'bootstrap-icons/icons/box-arrow-right.svg'


function Header(props) {

    return (
        <header className="p-3 mb-3 border-bottom">
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">

                    <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
                        <SvgHome className="bi me-2" width="32" height="32"/>
                    </a>

                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        {/* <li><a href="/" className="nav-link px-2 link-dark">Feuilles de temps</a></li>
                        <li><a href="/" className="nav-link px-2 link-dark">Facturation</a></li> */}
                    </ul>


                    <div className="col-md-6 text-end">

                        {props.authState === 'signedOut' &&
                            <Button variant="light" className="mx-1" onClick={() => Auth.federatedSignIn()}>
                                <span> Please Sign In </span>
                                <SvgSignin className="bi mx-1" width="32" height="32"/>
                            </Button>
                        }

                        {props.authState === 'signedIn' &&
                            <>
                            <span>{props.apiService.cognitoUser.username}</span>
                            <Button variant="light" className="mx-1" onClick={() => props.apiService.forceSignOut()}>
                                <SvgSignout className="bi mx-1" width="32" height="32"/>
                            </Button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
